<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="mt-8">
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="25%">
              {{ reserva.code }}
            </th>
            <th
              width="5%"
              class="text-center"
            >
              <v-btn
                icon
                @click="setShowItem"
              >
                <v-icon color="primary">
                  {{ showItem ? icons.mdiArrowDownCircle : icons.mdiArrowRightCircle }}
                </v-icon>
              </v-btn>
            </th>
            <th width="15%">
              {{ $t('lbl.document') }}
            </th>
            <th>
              {{ $t('lbl.action') }}
            </th>
            <th width="20%">
              {{ $t('lbl.date') }}
            </th>
            <th width="25%">
              {{ $t('lbl.user') }}
            </th>
            <th width="5%">
              <!--<v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSendOutline }}
                      </v-icon>
                      <span>{{ $t('btn.send') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiPrinter }}
                      </v-icon>
                      <span>{{ $t('btn.print') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        mdi-tray-arrow-down
                      </v-icon>
                      <span>{{ $t('btn.dowload') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>-->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!showItem">
            <td
              style="vertical-align: top;border: 0"
            >
              <span
                v-for="(carShop, ind) in reserva.car_shop"
                :key="ind"
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                {{
                  carShop.data_service.items.hotel.name
                }}
              </span>
            </td>
            <td
              style="vertical-align: top;border: 0"
              class="text-center"
            >
              <!--<v-checkbox
                v-if="showItem"
                v-model="item.history_document_item[showItem ? 0 : item.history_document_item.length - 1].check"
                class="my-0 ml-2"
              ></v-checkbox>-->
            </td>
            <td>
              {{ reserva.history_documents[reserva.history_documents.length - 1].document }}
            </td>
            <td>
              {{
                reserva.history_documents[reserva.history_documents.length - 1].actions[0].action
              }}
            </td>
            <td>
              {{
                $moment(
                  reserva.history_documents[reserva.history_documents.length - 1].actions[
                    reserva.history_documents[reserva.history_documents.length - 1].actions.length - 1
                  ].date,
                ).format('DD MMM, Y')
              }},
              {{
                reserva.history_documents[reserva.history_documents.length - 1].actions[
                  reserva.history_documents[reserva.history_documents.length - 1].actions.length - 1
                ].time
              }}
            </td>
            <td>
              {{
                reserva.history_documents[reserva.history_documents.length - 1].actions[
                  reserva.history_documents[reserva.history_documents.length - 1].actions.length - 1
                ].user
              }}<br />
              {{
                reserva.history_documents[reserva.history_documents.length - 1].actions[
                  reserva.history_documents[reserva.history_documents.length - 1].actions.length - 1
                ].role
              }}
              /
              {{
                reserva.history_documents[reserva.history_documents.length - 1].actions[
                  reserva.history_documents[reserva.history_documents.length - 1].actions.length - 1
                ].company
              }}
            </td>
            <td></td>
          </tr>
          <tr v-else>
            <td
              style="vertical-align: top;border: 0"
            >
              <span
                v-for="(carShop, ind) in reserva.car_shop"
                :key="ind"
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                <span v-if="carShop.entity === 'hotels'">
                  {{
                    carShop.data_service.items.hotel.name
                  }}
                </span>
              </span>
            </td>
            <td
              style="vertical-align: top;border: 0"
              class="text-center"
            >
            </td>
            <td
              style="vertical-align: top;border: 0"
            >
              <span
                v-for="(document, ind) in reserva.history_documents"
                :key="ind"
              >
                {{ document.document }}
                <v-tooltip
                  v-if="document.url"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadPdf(document.url)"
                    >
                      <v-icon>mdi-file-download-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('btn.dowload') }}
                  </span>
                </v-tooltip>
                <v-divider></v-divider>
              </span>
            </td>
            <td style="vertical-align: top;border: 0">
              <span
                v-for="(document, ind) in reserva.history_documents"
                :key="ind"
              >
                {{ document.actions[0].action }}
                <v-divider class="my-1"></v-divider>
              </span>
            </td>
            <td style="vertical-align: top;border: 0">
              <span
                v-for="(document, ind) in reserva.history_documents"
                :key="ind"
              >
                {{ $moment(
                  document.actions[0].date,
                ).format('DD MMM, Y') }}, {{ document.actions[0].time }}
                <v-divider class="my-1"></v-divider>
              </span>
            </td>
            <td
              style="vertical-align: top;border: 0"
              colspan="2"
            >
              <span
                v-for="(document, ind) in reserva.history_documents"
                :key="ind"
              >
                {{ document.actions[0].user }} / {{ document.actions[0].role }}
                ({{ document.actions[0].company }})
                <v-divider class="my-1"></v-divider>
              </span>
            </td>
          </tr>
          <!--<fragment v-if="showItem">
            <fragment
              v-for="(action, indA) in item.history_document_item[0].actions"
              :key="indA"
            >
              <tr v-if="indA > 0">
                <td>
                  {{ action.action }}
                </td>
                <td>
                  {{ $moment(action.date).format('DD MMM, Y') }},
                  {{ action.time }}
                </td>
                <td colspan="2">
                  {{ action.user }}<br />
                  {{ action.role }} / {{ action.company }}
                </td>
              </tr>
            </fragment>
          </fragment>

          <fragment v-if="showItem">
            <fragment
              v-for="(history, indHist) in item.history_document_item"
              :key="indHist"
            >
              <fragment v-if="indHist > 0">
                <fragment
                  v-for="(action, indAct) in history.actions"
                  :key="indAct"
                >
                  <tr v-if="indAct === 0">
                    <td
                      :rowspan="history.actions.length"
                      style="vertical-align: top;border: 0"
                    >
                      <v-checkbox
                        v-model="history.check"
                        class="my-0 ml-2"
                      ></v-checkbox>
                    </td>
                    <td
                      :rowspan="history.actions.length"
                      style="vertical-align: top;border: 0"
                    >
                      {{ history.document }}
                    </td>
                    <td>{{ action.action }}</td>
                    <td>
                      {{ $moment(action.date).format('DD MMM, Y') }},
                      {{ action.time }}
                    </td>
                    <td colspan="2">
                      {{ action.user }}<br />
                      {{ action.role }} / {{ action.company }}
                    </td>
                  </tr>
                  <tr v-if="indAct > 0">
                    <td>{{ action.action }}</td>
                    <td>
                      {{ $moment(action.date).format('DD MMM, Y') }},
                      {{ action.time }}
                    </td>
                    <td colspan="2">
                      {{ action.user }}<br />
                      {{ action.role }} / {{ action.company }}
                    </td>
                  </tr>
                </fragment>
              </fragment>
            </fragment>
          </fragment>-->
        </tbody>
      </template>
    </v-simple-table>

    <Loading
      v-if="loadingDescargar"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiArrowDownCircle,
  mdiArrowRightCircle,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiSendOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'

export default {
  components: {
    Loading,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    reserva: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiArrowDownCircle,
        mdiArrowRightCircle,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiSendOutline,
        mdiMapMarkerOutline,
      },
      permisos: sessionStorage.getItem('permisos_auth'),
      image: null,
      contrate: null,
      listStates: [],
      listConditions: [],
      idStateConfirm: 0,
      idStatePay: 0,
      idStateCancele: 0,
      showAllConditions: false,
      showModalCancele: false,
      loadingDescargar: false,
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
      indexCarItem: state => state.app.indexCarItem,
      showCarItem: state => state.app.showCarItem,
    }),
    showItem() {
      if (this.indexCarItem === this.pos && this.showCarItem) {
        return true
      }

      return false
    },
    cantActions() {
      let cant = 0

      this.reserva.history_documents.forEach(element => {
        cant += element.actions.length
      })

      return cant
    },
  },
  mounted() {
    // this.getContrate()
    this.setStates()
  },
  created() {
    this.getIdStatesPayment()
  },
  methods: {
    ...mapMutations(['setPosCotizador', 'updateIndexCarItem', 'updateShowCarItem']),
    getIdStatesPayment() {
      // PARA IDENTIFICAR EL ID DE CONFIRMADO
      if (this.states.filter(e => e.id === this.item.state_item && e.slug === 'confirmada').length > 0) {
        this.idStateConfirm = this.states.filter(e => e.id === this.item.state_item && e.slug === 'confirmada')[0].id
      }

      // PARA IDENTIFICAR EL ID DE PAGADO
      if (this.states.filter(e => e.id === this.item.state_item && e.slug === 'completada').length > 0) {
        this.idStatePay = this.states.filter(e => e.id === this.item.state_item && e.slug === 'completada')[0].id
      }
    },
    getContrate() {
      this.axios
        .get(`contrate_hotels/${this.item.data_service.items.contrate_id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.contrate = res.data.data.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setStates() {
      this.states.forEach(element => {
        if (element.slug === 'en-proceso') {
          this.listStates.push({
            id: element.id,
            name: 'En proceso',
          })
        } else if (element.slug === 'confirmada') {
          this.listStates.push({
            id: element.id,
            name: 'Confirmado',
          })
        } else if (element.slug === 'cancelada') {
          this.listStates.push({
            id: element.id,
            name: 'Cancelado',
          })
          this.idStateCancele = element.id
        } else if (element.slug === 'reembolsada') {
          this.listStates.push({
            id: element.id,
            name: 'Reembolsado',
          })
        } else if (element.slug === 'completada') {
          this.listStates.push({
            id: element.id,
            name: 'Completado',
          })
        }
      })
    },
    setStateItem() {
      this.getIdStatesPayment()

      const json = {
        reserve_id: sessionStorage.getItem('reservations-id'),
        state_item: this.item.state_item,
        code_item: this.item.code,
      }
      this.axios
        .post('reservations/update-state-item-car', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_state_item = res.data.data.history_state_item
            if (res.data.data.all_service_state) {
              this.$emit('updateStateReservaItem', this.item.state_item)

              // this.reserva.state_reserva_id = this.item.state_item
            }
          }
        })
    },
    setShowItem() {
      if (!this.showCarItem) {
        this.updateIndexCarItem(this.pos)
        this.updateShowCarItem(true)
      } else if (this.showCarItem && this.pos === this.indexCarItem) {
        this.updateIndexCarItem(-1)
        this.updateShowCarItem(false)
      } else if (this.showCarItem && this.pos !== this.indexCarItem) {
        this.updateIndexCarItem(this.pos)
      }
    },

    editServive() {
      const json = {
        pos: this.pos,
        item: this.item,
      }

      localStorage.setItem('car-shop-item', JSON.stringify(json))
      localStorage.setItem('route-redirect', 'reservations-update')

      this.$router.push({ name: 'car-shop-item-update' })
    },

    canceleService() {
      this.item.state_item = this.idStateCancele
      this.showModalCancele = false

      const json = {
        reserve_id: sessionStorage.getItem('reservations-id'),
        state_item: this.item.state_item,
        code_item: this.item.code,
      }
      this.axios
        .post('reservations/update-state-item-car', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.item.history_state_item = res.data.data.history_state_item
            if (res.data.data.all_service_state) {
              this.$emit('updateStateReservaItem', this.item.state_item)
            }
          }
        })
    },
    downloadPdf(url) {
      this.loadingDescargar = true
      const json = {
        url,
      }
      this.axios
        .post('reservations/download-s3', json, { responseType: 'arraybuffer' })
        .then(res => {
          const filename = this.reserva.code

          this.downloadFile(res, filename)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loadingDescargar = false
        })
    },
    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
