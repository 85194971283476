+<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.booking')} / ${$t('menu.reservas')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'reservations' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <!--<v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>-->
            <v-spacer></v-spacer>
            <!--<v-col
              cols="12"
              md="2"
              class="text-right"
            >
              <v-btn
                color="primary"
                outlined
                @click="print()"
              >
                <v-icon class="mr-2">
                  {{ icons.mdiPrinter }}
                </v-icon>
                {{ $t('btn.print') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="text-center"
            >
              <v-btn
                color="primary"
                outlined
                @click="sendEmail()"
              >
                <v-icon class="mr-2">
                  {{ icons.mdiSendOutline }}
                </v-icon>
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>-->
            <v-col
              v-if="!item.pagada"
              cols="12"
              md="2"
              class="text-right mr-2"
            >
              <v-btn
                color="primary"
                outlined
                :disabled="validatePay"
                @click="pay()"
              >
                <v-icon class="mr-2">
                  {{ icons.mdiAccountCashOutline }}
                </v-icon>
                {{ `${$t('btn.payReserve')}` }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="1"
            ></v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="!isLoading">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <b>{{ $t('lbl.reserve') }}</b>: {{ item.code }}<br />
              <template v-if="item.cliente">
                <template v-if="item.cliente.name">
                  <b>{{ $t('lbl.client') }}</b>: {{ `${item.cliente.name} ${item.cliente.apellidos ? item.cliente.apellidos : ''}` }}<br />
                </template>
              </template>
              <b>{{ $t('lbl.agency') }}</b>: {{ item.afiliado.nivel === 0 ? name_owner : item.afiliado.company }}
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <b>{{ $t('lbl.state') }}</b>: {{ item.state_reserva.name }}<br />
              <b>{{ $t('lbl.homeService') }}</b>: {{ $moment(item.date_servicio[0]).format('DD MMM Y') }} 16:00<br />
              <fragment v-if="item.date_servicio_end.length > 0">
                <b>{{ $t('lbl.endService') }}</b>: {{ $moment(item.date_servicio_end[0]).format('DD MMM Y') }} 12:00<br />
              </fragment>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <!--<v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  <b>{{ $t('register.moneda') }}</b>: USD<br />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <b>{{ $t('lbl.ctaXCobrar') }}</b>: {{ item.pricePayOnline | currency }}<br />
                  <b>{{ $t('lbl.cobrado') }}</b>: {{ item.priceCobrado | currency }}<br />
                  <b>{{ $t('lbl.pendiente') }}</b>: {{ item.pricePendiente | currency }}<br />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <b>{{ $t('lbl.ctaXPagar') }}</b>: {{ item.priceAllPagar | currency }}<br />
                  <b>{{ $t('lbl.pagado') }}</b>: {{ item.pricePagado | currency }}<br />
                  <b>{{ $t('lbl.pendiente') }}</b>: {{ item.pricePagarPendiente | currency }}<br />
                </v-col>
              </v-row>-->
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col
              cols="12"
              md="12"
            >
              <v-tabs v-model="pos">
                <v-tab>
                  {{ $t('lbl.services') }}
                </v-tab>
                <v-tab>
                  {{ $t('lbl.transactions') }}
                </v-tab>
                <v-tab>
                  {{ $t('lbl.documents') }}
                </v-tab>
                <v-tab>
                  {{ $t('lbl.history') }}
                </v-tab>
              </v-tabs>

              <v-divider></v-divider>

              <v-tabs-items v-model="pos">
                <v-tab-item @click="setPosCotizador(0)">
                  <template v-if="!isLoadingServices">
                    <Services
                      v-if="!searchingHotel"
                      :reserva="item"
                      :conditions="conditions"
                      :rooms="rooms"
                      :planes="planes"
                      :user="user"
                      @updateStateReserva="updateStateReserva"
                      @getItem="getItem"
                    />
                  </template>
                </v-tab-item>
                <v-tab-item @click="setPosCotizador(1)">
                  <Transaction :reserva="item" />
                </v-tab-item>
                <v-tab-item @click="setPosCotizador(2)">
                  <Documents :reserva="item" />
                </v-tab-item>
                <v-tab-item @click="setPosCotizador(3)">
                  <Historial :reserva="item" />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>

          <SidebarDataPays
            v-if="isSidebarDataPaysActive"
            v-model="isSidebarDataPaysActive"
            from="pays"
            :user="user"
            :car-items="itemsPay"
            @confirmPay="confirmPay"
          />
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiPrinter,
  mdiEmail,
  mdiSendOutline,
  mdiAccountCashOutline,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import Services from './tabs/Services.vue'
import Documents from './tabs/Documents.vue'
import Historial from './tabs/Historial.vue'
import Transaction from './tabs/Transaction.vue'
import SidebarDataPays from '../utils/SidebarDataPays.vue'

export default {
  components: {
    AppCardCode,
    Services,
    Documents,
    Historial,
    Transaction,
    SidebarDataPays,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      isLoadingServices: true,
      isSidebarDataPaysActive: false,
      itemsPay: [],
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      name_owner: process.env.VUE_APP_OWNER,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiPrinter,
        mdiEmail,
        mdiSendOutline,
        mdiAccountCashOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      editReserva: false,
      editStatus: false,
      status: [],
      statusList: [],
      slugState: null,
      idConfirmState: 0,
      item: {},
      product: {},
      clients: {},
      diasReservar: 0,
      rentadora: {},
      recogida: {},
      entrega: {},
      dateRecogida: new Date(),
      dateEntrega: new Date(),
      image: null,
      data_markups: {},
      precioSuplementPayOnline: 0,
      precioSuplementPayRentator: 0,
      precioTarifaRetorno: 0,

      loading: false,
      loadingPvP: false,

      categories: [],
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      isDialogEmails: false,
      email: null,
      loadingEmail: false,
      pos: 0,
      conditions: [],
      rooms: [],
      planes: [],
      user: {},
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
      comentsClient: state => state.app.comentsClient,
      comentsGestor: state => state.app.comentsGestor,
      posCotizador: state => state.app.posCotizador,
      searchingHotel: state => state.app.searchingHotel,
    }),
    validatePay() {
      let result = true

      // this.item.state_reserva.name === 'Confirmada' &&
      if (this.item.afiliado.nivel === this.user.nivel) {
        result = false
      }

      return result
    },
  },
  mounted() {
    // this.pos = this.posCotizador
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.profile()
    this.getStatus()
    this.getConditions()
    this.getRooms()
    this.getPlanes()
    if (sessionStorage.getItem('reservations-id') !== null) {
      this.getItem()
    } else {
      this.$router.push({ name: 'reservations' })
    }
  },
  methods: {
    ...mapMutations([
      'setPosCotizador',
      'setOnlyShow',
      'updateCommentsClient',
      'addCommentsClient',
      'updateCommentsGestor',
      'addCommentsGestor',
      'updateLoadingBtn',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
    },
    getCar() {
      if (this.product.galery === null) {
        this.image = 'config/car_placeholder.png'
      } else {
        this.image = this.product.galery_random
      }
      setTimeout(() => {
        this.isLoading = false
      }, 200)
    },
    getStatus() {
      this.axios
        .get('state_reservations?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.status = res.data.data
          this.status.forEach(element => {
            if (element.slug === 'confirmada') {
              this.idConfirmState = element.id
            }
          })
        })
    },
    updateStateReserva(state) {
      this.item.state_reserva_id = state
      this.item.state_reserva.name = this.status.filter(e => e.id === state)[0].name
    },

    checkStatus() {
      this.statusList = []
      this.slugState = this.item.state_reserva.slug
      if (this.item.state_reserva.slug === 'en-proceso') {
        this.status.forEach(element => {
          if (element.slug === 'en-proceso' || element.slug === 'confirmada' || element.slug === 'cancelada') {
            this.statusList.push(element)
          }
        })
      } else if (this.item.state_reserva.slug === 'confirmada') {
        this.status.forEach(element => {
          if (element.slug === 'confirmada' || element.slug === 'cancelada') {
            this.statusList.push(element)
          }
        })
        this.editReserva = false
      } else if (this.item.state_reserva.slug === 'cancelada') {
        this.status.forEach(element => {
          if (element.slug === 'cancelada'/* || element.slug === 'reembolsada' */) {
            this.statusList.push(element)
          }
        })
        this.editReserva = false
      }
    },
    getConditions() {
      this.axios
        .get('nom_conditions_generals?for_car=1&per_page=1000000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.conditions = res.data.data
          }
        })
    },
    getRooms() {
      this.axios
        .get('nom_habitacion_hotels?for_car=1&per_page=1000000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.rooms = res.data.data
          }
        })
    },
    getPlanes() {
      this.axios
        .get('nom_regimen_alimenticio?for_car=1&per_page=1000000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.planes = res.data.data
          }
        })
    },

    getItem() {
      this.isLoadingServices = true
      this.axios
        .get(`reservations/${sessionStorage.getItem('reservations-id')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.item = res.data.data.data
        })
        .finally(() => {
          this.isLoading = false
          this.isLoadingServices = false
        })
    },
    save() {
      const json = {
        id: this.item.id,
        car_shop: this.item.car_shop,
      }

      this.axios
        .post(`reservations/update-status/${this.item.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.$router.push({ name: 'reservations' })
        })

      /* let proceder = false
      if (this.idConfirmState === this.item.state_reserva_id) {
        if (this.item.number_confirmed) {
          proceder = true
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        proceder = true
      }
      if (proceder) {
        const json = {
          state_reserva_id: this.item.state_reserva_id,
          number_confirmed: this.item.number_confirmed,
          coment_gestor: this.comentsGestor,
          coment_client: this.comentsClient,
        }
        this.axios
          .post(`reservations/update-status/${this.item.id}`, json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.reseat()
            this.getItem()

            this.$toast.success(this.$t('msg.infoSuccess'))

            // this.$router.push({ name: 'hotels-list' })
          })
      } */
    },
    resetPvP() {
      this.loadingPvP = true
      this.getItem()
    },
    reseat() {
      this.editStatus = false
      this.loading = false
    },
    print() {
      this.$htmlToPaper('printMe')
    },
    sendEmail() {
      this.isDialogEmails = true
    },
    sendNotification() {
      if (this.email) {
        this.loadingEmail = true
        const json = {
          email: this.email,
        }
        this.axios
          .post(`reservations/update-emails/${sessionStorage.getItem('reservations-id')}`, json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              this.$toast.success(this.$t('msg.sendNotification'))

              // this.$router.push({ name: 'cars-contrate-list' })
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loadingEmail = false
            this.isDialogEmails = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    pay() {
      const carShop = []
      this.item.car_shop.forEach(element => {
        carShop.push({
          check: true,
          ...element,
        })
      })
      this.itemsPay = carShop
      this.isSidebarDataPaysActive = true
    },
    confirmPay(json) {
      const procesar = true

      /* const arrItemsCheck = []
      this.carItems.forEach(element => {
        if (element.check) {
          arrItemsCheck.push(element)
        }
      }) */

      if (procesar/* && arrItemsCheck.length > 0 */) {
        this.updateLoadingBtn(true)
        const jsonCar = {
          // items: arrItemsCheck,
          // data_client: this.clientCarShop,
          id: this.item.id,
          action: 'pay',
          slug: json.slug,
          evidencia: json.evidencia,
          creditPay: json.creditPay,
          password: json.password,
          saldoFavorPay: json.saldoFavorPay,
          cardName: json.cardName,
          cardNo: json.cardNo,
          cardDateExpiret: json.cardDateExpiret,
          cardCVV: json.cardCVV,
          payWithPayPal: json.payWithPayPal,
          orderIdPayPal: json.orderIdPayPal,
          payWithSibs: json.payWithSibs,
          transactionID: json.transactionID,
        }

        this.axios
          .post('reservations/pay', jsonCar, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              this.$toast.success(this.$t('msg.infoSuccess'))

              this.$router.push({ name: 'reservations' })
            } else if (res.data.data.status === 302) {
              this.$toast.error(this.$t('msg.passwordNotMath'))
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.updateLoadingBtn(false)
            this.isSidebarDataPaysActive = false
          })
      }
    },
  },
}
</script>
