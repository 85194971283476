<template>
  <div v-if="!isLoading">
    <Loading
      v-if="searchingHotel"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
    <fragment
      v-for="(car, indCar) in reserva.car_shop"
      :key="indCar"
    >
      <ServiceCar
        v-if="car.entity === 'cars'"
        :pos="indCar"
        :reserva="reserva"
        :item="car"
        :states="states"
        :conditions="conditions"
        :user="user"
        @updateStateReservaItem="updateStateReservaItem"
      />
      <ServiceHotel
        v-else-if="car.entity === 'hotels'"
        :pos="indCar"
        :reserva="reserva"
        :item="car"
        :states="states"
        :conditions="conditions"
        :rooms="rooms"
        :planes="planes"
        :user="user"
        @updateStateReservaItem="updateStateReservaItem"
      />
    </fragment>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import ServiceCar from './ServiceCar.vue'
import ServiceHotel from './ServiceHotel.vue'

export default {
  components: {
    ServiceCar,
    ServiceHotel,
    Loading,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    reserva: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    conditions: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    rooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    planes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
      },
      isDialogVisibleDelete: false,
      isDialogVisible: false,
      item: {},
      itemId: null,
      loading: false,
      reservando: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      states: [],
    }
  },
  computed: {
    ...mapState({
      searchingHotel: state => state.app.searchingHotel,
    }),
  },
  created() {
    this.getStates()
  },
  methods: {
    ...mapMutations([
      'setSearchingHotel',
    ]),
    getStates() {
      this.axios
        .get('state_reservations?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.states = res.data.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateStateReservaItem(state) {
      this.$emit('updateStateReserva', state)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
